import React, { memo } from 'react'
import { useRoutes } from 'react-router-dom';
import routes from './router';
import Footer from './pages/Footer'
import Header from './pages/Header'
import './style/common.scss'
const App = memo(() => {
  // console.log = () => {}  //禁止打印输出
  return (
    <div className="app">
      <div className="app_header">
        <div className='app_header_item'><Header /></div>
      </div>
      <div className="app_content">
        <div className='app_content_item'>{useRoutes(routes)}</div>
      </div>
      <div className="app_footer">
        <div className='app_footer_item'><Footer/></div>
      </div>
    </div>
  )
})
 
export default App