import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {BrowserRouter } from 'react-router-dom'
import Loading from './pages/Other'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //当组件尚未加载完成时，Suspense 会显示 fallback 指定的内容
  <Suspense fallback={<Loading />}>
     <BrowserRouter>
       <App />
     </BrowserRouter>
  </Suspense>

)

/** 边写边记 
 *  路由模式
 *  import {BrowserRouter,HashRouter } from 'react-router-dom'
 * 
 *  a：history模式
 *    <Suspense fallback={<Loading />}>
        <BrowserRouter>//history
          <App />
        </BrowserRouter>
      </Suspense>
 * 
    b：hash模式
      <Suspense fallback={<Loading />}>
        <HashRouter> // hash
          <App />
        </HashRouter>
      </Suspense>
 * **/

