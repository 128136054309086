import './header.scss';
import { useState } from 'react';

const Header = () => {
  const tabList = ['首页', '作品介绍', '个人信息'];
  const [clickIndex, setClickIndex] = useState(0);


  // 处理点击事件，点击时滚动到对应的部分
  const handleClick = (tab, idx) => {
    setClickIndex(idx); // 更新 clickIndex 为当前点击的 tab 索引
    // welcome: 890, description: 1130, skill: 1930, work: 2680, project: 3362
    if (tab === '作品介绍') {
        window.scrollTo({ top: 1127, behavior: 'smooth' });
    } else if (tab === '个人信息') {
        window.scrollTo({ top: 3360, behavior: 'smooth' });
    }else{
        window.location.reload()
    }
  };

  return (
    <div className='header'>
      <div className='header_left_box'>
        <div className='text_box'>
          <div className='text'>Welcome To My Home</div>
        </div>
      </div>
      <div className='header_right_box'>
        <div className='tabs_box'>
          {tabList.map((itm, idx) => (
            <div
              className={`tabs_title ${clickIndex === idx ? 'active' : ''}`}
              key={idx}
              onClick={() => handleClick(itm, idx)}
            >
              {itm}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;


/** 边写边记
 *  1、便利数组在React中得使用map，回返回一个新数组 不能用forEach
 *  2、当用map 便利数组是 若 map 内使用的是 { } 则需要return <元素> ； 若map内使用的是 （ ），则不需要使用return《参照上述代码》
 *  3、在onClick中 如果 不用 箭头函数的话 则会在渲染时 立即调用; 使用箭头函数则在点击的时候调用
 * 
 * **/